import { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.css";

import APPLICATION_CONSTANTS from "./strings/common_strings";

export default function ContactMe() {
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_egymxpg",
        "template_h284xey",
        form.current,
        "3cy4HhSGqTSy-dYlv"
      )
      .then(
        (result) => {
          console.log(result.textarea);
        },
        (error) => {
          console.error(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <section id="contact">
      <div class="contact-section">
        <p className="title" style={{ color: "black" }}>
          {APPLICATION_CONSTANTS.CHAT_TITLE}
        </p>
        <div className="form-section">
          <form ref={form} onSubmit={sendEmail}>
            <div className="row-form">
              <div className="form-name">
                <div className="form-group">
                  <input
                    placeholder={APPLICATION_CONSTANTS.NAME_PLACEHOLDER}
                    type="text"
                    name="user_name"
                    id="name"
                    required
                  ></input>
                </div>
              </div>
              <div className="form-email">
                <div className="form-group">
                  <input
                    placeholder={APPLICATION_CONSTANTS.EMAIL_PLACEHOLDER}
                    type="email"
                    id="email"
                    name="user_email"
                    required
                  ></input>
                </div>
              </div>
            </div>
            <div className="form-group">
              <textarea
                placeholder={APPLICATION_CONSTANTS.MESSAGE_PLACEHOLDER}
                class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                type="text"
                id="message"
                required
                name="user_message"
              ></textarea>
            </div>
            <button style={{ color: "white" }} type="submit">
              {APPLICATION_CONSTANTS.SEND}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
}
