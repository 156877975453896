import * as React from "react";
import { Card, CardTitle, CardSubtitle, CardImg } from "reactstrap";

import b1 from "../img/bluesky.jpg";
import locationFinder from "../img/location_finder.png";
import iot from "../img/iot.png";
import car_loan from "../img/car_loan.png";
import chatApp from "../img/chatapp.png";
import harmoni from "../img/harmoni.jpg";
import decardIt from "../img/decardIt.png";
import roboEscape from "../img/robo.png";

import "./Projects.css";
import "./Experience.css";
import APPLICATION_CONSTANTS from "./strings/common_strings.js";

export default function Projects() {
  return (
    <section id="projects">
      <div
        className="project-section-1"
        style={{
          width: "100%",
          position: "relative",
          height: "auto",
        }}
      >
        <p className="title">{APPLICATION_CONSTANTS.PROJECTS_TITLE}</p>
        <div className="cards-section">
          <Card style={{ width: "100%" }}>
            <CardImg width="10px" src={b1} alt={"sky"} />
            <CardTitle style={{ color: "white", textAlign: "center" }}>
              {APPLICATION_CONSTANTS.PROJECT_1_TITLE}{" "}
            </CardTitle>
            <CardSubtitle
              style={{ textAlign: "center", fontSize: "10px", color: "orange" }}
            >
              {APPLICATION_CONSTANTS.PROJECT_1_SUBTITLE}{" "}
            </CardSubtitle>
            <a href="#about" class="align-self-center">
              {" "}
              <button
                style={{ margin: "25px" }}
                type="button"
                class="align-self-center btn btn-lg btn-block btn-primary"
              >
                Visit Site
              </button>
            </a>
          </Card>
          <Card style={{ width: "100%" }}>
            <CardImg width="100%" src={decardIt} alt={"decardIt"} />
            <CardTitle style={{ color: "white", textAlign: "center" }}>
              {APPLICATION_CONSTANTS.PROJECT_2_TITLE}
            </CardTitle>
            <CardSubtitle
              style={{ textAlign: "center", fontSize: "10px", color: "orange" }}
            >
              {APPLICATION_CONSTANTS.PROJECT_2_SUBTITLE},{" "}
            </CardSubtitle>
            <a
              class="align-self-center"
              href="https://play.google.com/store/apps/details?id=com.decardit.myapplication"
            >
              {" "}
              <button
                style={{ margin: "25px" }}
                type="button"
                class="align-self-center btn btn-lg btn-block btn-primary"
              >
                {APPLICATION_CONSTANTS.LINK_TO_PLAYSTORE}
              </button>
            </a>
          </Card>
          <Card style={{ width: "100%" }}>
            <CardImg src={locationFinder} alt={"sky"} />
            <CardTitle style={{ color: "white", textAlign: "center" }}>
              {APPLICATION_CONSTANTS.PROJECT_3_TITLE}
            </CardTitle>
            <CardSubtitle
              style={{ textAlign: "center", fontSize: "10px", color: "orange" }}
            >
              {APPLICATION_CONSTANTS.PROJECT_3_SUBTITLE}
            </CardSubtitle>
            <a
              class="align-self-center"
              href="https://github.com/IreniR/LocationFinder"
            >
              <button
                style={{ margin: "25px" }}
                type="button"
                class="align-self-center btn btn-lg btn-block btn-primary"
              >
                {APPLICATION_CONSTANTS.LINK_TO_PROJECT}
              </button>
            </a>
          </Card>
          <Card style={{ width: "100%" }}>
            <CardImg width="100%" src={chatApp} alt={"sky"} />
            <CardTitle style={{ color: "white", textAlign: "center" }}>
              {APPLICATION_CONSTANTS.PROJECT_4_TITLE}
            </CardTitle>
            <CardSubtitle
              style={{ textAlign: "center", fontSize: "10px", color: "orange" }}
            >
              {APPLICATION_CONSTANTS.PROJECT_4_SUBTITLE}
            </CardSubtitle>
            <a
              class="align-self-center"
              href="https://github.com/IreniR/SOFE4640Project"
            >
              <button
                style={{ margin: "25px" }}
                type="button"
                class="align-self-center btn btn-lg btn-block btn-primary"
              >
                {APPLICATION_CONSTANTS.LINK_TO_PROJECT}
              </button>
            </a>
          </Card>
          <Card style={{ width: "100%" }}>
            <CardImg width="100%" src={car_loan} alt={"sky"} />
            <CardTitle style={{ color: "white", textAlign: "center" }}>
              {APPLICATION_CONSTANTS.PROJECT_5_TITLE}
            </CardTitle>
            <CardSubtitle
              style={{ textAlign: "center", fontSize: "10px", color: "orange" }}
            >
              {APPLICATION_CONSTANTS.PROJECT_5_SUBTITLE}
            </CardSubtitle>
            <a
              class="align-self-center"
              href="https://github.com/IreniR/mortgageCalculator"
            >
              {" "}
              <button
                style={{ margin: "25px" }}
                type="button"
                class="align-self-center btn btn-lg btn-block btn-primary"
              >
                {APPLICATION_CONSTANTS.LINK_TO_PROJECT}
              </button>
            </a>
          </Card>
          <Card style={{ width: "100%" }}>
            <CardImg width="100%/5" src={harmoni} alt={"sky"} />
            <CardTitle style={{ color: "white", textAlign: "center" }}>
              {APPLICATION_CONSTANTS.PROJECT_6_TITLE}
            </CardTitle>
            <CardSubtitle
              style={{ textAlign: "center", fontSize: "10px", color: "orange" }}
            >
              {APPLICATION_CONSTANTS.PROJECT_6_SUBTITLE}
            </CardSubtitle>
            <a
              class="align-self-center"
              href="https://github.com/IreniR/virtual-health-assistant-user-app"
            >
              {" "}
              <button
                style={{ margin: "25px" }}
                type="button"
                class="align-self-center btn btn-lg btn-block btn-primary"
              >
                {APPLICATION_CONSTANTS.LINK_TO_PROJECT}
              </button>
            </a>
          </Card>
          <Card style={{ width: "100%" }}>
            <CardImg width="100%/5" src={iot} alt={"iot"} />

            <CardTitle style={{ color: "white", textAlign: "center" }}>
              {APPLICATION_CONSTANTS.PROJECT_7_TITLE}
            </CardTitle>
            <CardSubtitle
              style={{ textAlign: "center", fontSize: "10px", color: "orange" }}
            >
              {APPLICATION_CONSTANTS.PROJECT_7_SUBTITLE}{" "}
            </CardSubtitle>
            <a
              class="align-self-center"
              href="https://github.com/IreniR/SOFE4610Project-Group4"
            >
              <button
                style={{ margin: "25px" }}
                type="button"
                class="align-self-center btn btn-lg btn-block btn-primary"
              >
                {APPLICATION_CONSTANTS.LINK_TO_PROJECT}
              </button>
            </a>
          </Card>
          <Card style={{ width: "100%" }}>
            <CardImg width="100%" src={roboEscape} alt={"roboEscape"} />
            <CardTitle style={{ color: "white", textAlign: "center" }}>
              {APPLICATION_CONSTANTS.PROJECT_8_TITLE}
            </CardTitle>
            <CardSubtitle
              style={{ textAlign: "center", fontSize: "10px", color: "orange" }}
            >
              {APPLICATION_CONSTANTS.PROJECT_8_SUBTITLE}
            </CardSubtitle>
            <button
              style={{
                margin: "25px",
                backgroundColor: "grey",
                border: "none",
              }}
              type="button"
              class="align-self-center btn btn-lg btn-block btn-primary"
            >
              {APPLICATION_CONSTANTS.LINK_TO_PLAYSTORE}
            </button>
          </Card>
        </div>
      </div>
    </section>
  );
}
