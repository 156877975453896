import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import "./Experience.css";
import { FaBriefcase } from "react-icons/fa";
import APPLICATION_CONSTANTS from "./strings/common_strings";

export default function Experience() {
  return (
    <section id="experience">
      <div
        style={{
          backgroundImage: `linear-gradient(rgb(19, 55, 199), rgb(72, 199, 148))`,
        }}
      >
        ;<p className="title">{APPLICATION_CONSTANTS.EXPERIENCE_TITLE}</p>
        <VerticalTimeline>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date={APPLICATION_CONSTANTS.JOB_DATE_1}
            iconStyle={{ background: "rgb(233, 30, 99)", color: "#white" }}
            icon={<FaBriefcase />}
          >
            <h3 className="vertical-timeline-element-title">
              {APPLICATION_CONSTANTS.JOB_1_TITLE}
            </h3>
            <h4
              className="vertical-timeline-element-subtitle"
              style={{ fontSize: "15px", color: "gray", fontWeight: "normal" }}
            >
              {" "}
              {APPLICATION_CONSTANTS.JOB_1_LOCATION}
            </h4>
            <p
              style={{
                margin: "0",
                padding: "0",
                paddingTop: "5px",
                fontWeight: "normal",
              }}
            >
              <small>{APPLICATION_CONSTANTS.JOB_DETAIL_1}</small>
            </p>
            <p style={{ margin: "0", padding: "0", fontWeight: "normal" }}>
              <small>{APPLICATION_CONSTANTS.JOB_DETAIL_2}</small>
            </p>
            <p style={{ margin: "0", padding: "0", fontWeight: "normal" }}>
              <small>{APPLICATION_CONSTANTS.JOB_DETAIL_3}</small>
            </p>
            <p style={{ margin: "0", padding: "0", fontWeight: "normal" }}>
              <small></small>
            </p>
            <p style={{ margin: "0", padding: "0", fontWeight: "normal" }}>
              <small>{APPLICATION_CONSTANTS.JOB_DETAIL_4}</small>
            </p>
            <p style={{ margin: "0", padding: "0", fontWeight: "normal" }}>
              <small>{APPLICATION_CONSTANTS.JOB_DETAIL_5}</small>
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date={APPLICATION_CONSTANTS.JOB_DATE_2}
            iconStyle={{ background: "rgb(33, 150, 243)", color: "black" }}
            icon={<FaBriefcase />}
          >
            <h3 className="vertical-timeline-element-title">
              {APPLICATION_CONSTANTS.JOB_TITLE_2}
            </h3>
            <h4
              className="vertical-timeline-element-subtitle"
              style={{ fontSize: "15px", color: "gray", fontWeight: "normal" }}
            >
              {APPLICATION_CONSTANTS.JOB_1_LOCATION}
            </h4>
            <p
              style={{
                margin: "0",
                padding: "0",
                paddingTop: "5px",
                fontWeight: "normal",
              }}
            >
              <small>{APPLICATION_CONSTANTS.JOB_2_DETAIL_1}</small>
            </p>
            <p style={{ margin: "0", padding: "0", fontWeight: "normal" }}>
              <small>{APPLICATION_CONSTANTS.JOB_2_DETAIL_2}</small>
            </p>
            <p style={{ margin: "0", padding: "0", fontWeight: "normal" }}>
              <small>{APPLICATION_CONSTANTS.JOB_2_DETAIL_3}</small>
            </p>
            <p style={{ margin: "0", padding: "0", fontWeight: "normal" }}>
              <small>{APPLICATION_CONSTANTS.JOB_2_DETAIL_4}</small>
            </p>
            <p style={{ margin: "0", padding: "0", fontWeight: "normal" }}>
              <small>{APPLICATION_CONSTANTS.JOB_2_DETAIL_5}</small>
            </p>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </div>
    </section>
  );
}
