import Header from "./components/Header";
import Skills from "./components/Skills";
import ContactMe from "./components/ContactMe";
import Projects from "./components/Projects";
import Experience from "./components/Experience";
import AboutMe from "./components/AboutMe";
import Certificates from "./components/Certificates";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
export default function App() {
  return (
      <div>
        <Header style={{ zindex:"1"}} />
        <AboutMe />
        <Experience />
        <Projects />
        <Skills />
        <Certificates />
        <ContactMe />
      </div>
  );
}
