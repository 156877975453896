import { Card, CardTitle, CardSubtitle } from "reactstrap";
import * as React from "react";
import "./Certificates.css";

import APPLICATION_CONSTANTS from "./strings/common_strings";

export default function Certificates() {
  return (
    <section id="cert">
      <div className="cert-section">
        <p className="title">{APPLICATION_CONSTANTS.CERTIFICATES_TITLE}</p>
        <Card>
          <CardTitle
            style={{ color: "white", textAlign: "center", marginTop: "5px" }}
          >
            {APPLICATION_CONSTANTS.CERT_TITLE_1}
          </CardTitle>
          <CardSubtitle
            style={{ textAlign: "center", fontSize: "10px", color: "white" }}
          >
            {APPLICATION_CONSTANTS.CERT_RECEIVED_1}
          </CardSubtitle>
          <a href={APPLICATION_CONSTANTS.CERT_1_URL}> </a>{" "}
          <button
            style={{
              marginTop: "25px",
              marginBottom: "25px",
              marginLeft: "60px",
              marginRight: "60px",
            }}
            type="button"
            class="align-self-center btn btn-lg btn-block btn-primary"
          >
            {APPLICATION_CONSTANTS.LINK_CERT_BTN}
          </button>
        </Card>
        <Card>
          <CardTitle
            style={{ color: "white", textAlign: "center", marginTop: "5px" }}
          >
            {APPLICATION_CONSTANTS.CERT_TITLE_2}
          </CardTitle>
          <CardSubtitle
            style={{ textAlign: "center", fontSize: "10px", color: "white" }}
          >
            {APPLICATION_CONSTANTS.CERT_RECEIVED_2}
          </CardSubtitle>
          <a href={APPLICATION_CONSTANTS.CERT_2_URL}> </a>{" "}
          <button
            style={{
              marginTop: "25px",
              marginBottom: "25px",
              marginLeft: "60px",
              marginRight: "60px",
            }}
            type="button"
            class="align-self-center btn btn-lg btn-block btn-primary"
          >
            {APPLICATION_CONSTANTS.LINK_CERT_BTN}
          </button>
        </Card>
        <Card>
          <CardTitle
            style={{ color: "white", textAlign: "center", marginTop: "5px" }}
          >
            {APPLICATION_CONSTANTS.CERT_TITLE_3}
          </CardTitle>
          <CardSubtitle
            style={{ textAlign: "center", fontSize: "10px", color: "white" }}
          >
            {APPLICATION_CONSTANTS.CERT_RECEIVED_3}
          </CardSubtitle>
          <a href={APPLICATION_CONSTANTS.CERT_3_URL}> </a>{" "}
          <button
            style={{
              marginTop: "25px",
              marginBottom: "25px",
              marginLeft: "60px",
              marginRight: "60px",
            }}
            type="button"
            class="align-self-center btn btn-lg btn-block btn-primary"
          >
            {APPLICATION_CONSTANTS.LINK_CERT_BTN}
          </button>
        </Card>
        <br></br>
        <br></br>
      </div>
    </section>
  );
}
