const APPLICATION_CONSTANTS = {
  PROJECTS_TITLE: "Projects",
  ABOUT_TITLE: "Hi, there! I'm Ireni",
  ABOUT_NAV: "About",
  EXPERIENCE_TITLE: "Professional Work Experience",
  EXPERIENCE_NAV: "Experience",
  SKILLS_TITLE: "Skills",
  TSKILLS_TITLE: "Technical Skills",
  CERTIFICATES_TITLE: "Certificates",
  CONTACT_TITLE: "Contact",

  LANGUAGES_PROTOCOLS: "Language & Protocols",
  SERVICES: "Services",

  CHAT_TITLE: "Interested in having a chat?",
  SEND: "Send",
  NAME_PLACEHOLDER: "Name",
  EMAIL_PLACEHOLDER: "Email",
  MESSAGE_PLACEHOLDER: "Enter your message",

  LINK_CERT_BTN: "Link to Certificate",
  CERT_TITLE_1: "GCP - Core Infrastrucure",
  CERT_TITLE_2: "Fundamental Cloud Concepts for AWS",
  CERT_TITLE_3:
    "Continuous Delivery and DevOps w/ Azure DevOps: The Big Picture",
  CERT_RECEIVED_1: "By Google Cloud (PluralSight - Completed Jul 20, 2022)",
  CERT_RECEIVED_2: "PluralSight - Completed Jul 19, 2022",
  CERT_RECEIVED_3: "PluralSight - Completed Jul 12, 2022",
  CERT_1_URL:
    "https://github.com/IreniR/Certificates/blob/main/gcp_fundamentals.pdf",
  CERT_2_URL:
    "https://github.com/IreniR/Certificates/blob/main/fundamental_cloud_concepts_for_AWS.pdf",
  CERT_3_URL:
    "https://github.com/IreniR/Certificates/blob/main/continuous_delivery_and_devops_with_azure_devops.pdf",

  LINK_TO_PROJECT: "Link to Projet",
  LINK_TO_PLAYSTORE: "Visit Playstore",

  PROJECT_1_TITLE: "My Portfolio",
  PROJECT_2_TITLE: "Decard It",
  PROJECT_3_TITLE: "Location Finder",
  PROJECT_4_TITLE: "Real-time Chat Application",
  PROJECT_5_TITLE: "Mortage and Car loan calculator",
  PROJECT_6_TITLE: "Harmoni - Virtual Health Assistant AI",
  PROJECT_7_TITLE: "Temperature Monitoring System (IoT)",
  PROJECT_8_TITLE: "Robo Escape (Work in Progress)",
  PROJECT_1_SUBTITLE: "Web Dev (React, BootStrap, JS, HTML, CSS, JSON)",
  PROJECT_2_SUBTITLE:
    "Android-(Kotlin, SQLite, GoogleAds) - Can be found in playstore",
  PROJECT_3_SUBTITLE:
    "Android - Java, Geocoding, Reverse GeoCoding, Location Refinement, SQLite",
  PROJECT_4_SUBTITLE: "Android - Java, Firebase",
  PROJECT_5_SUBTITLE:
    "Socket Programming (Java Web Sockets, JavaRMI, JeroMQ/ZeroMQ, Threads)",
  PROJECT_6_SUBTITLE: "Flutter, Dart, ML, Firebase, MQTT",
  PROJECT_7_SUBTITLE:
    "Digital Ocean Services, Web Socket, MySQL, Node-Red, MQTT",
  PROJECT_8_SUBTITLE: "Unity/C#, Google Ads",

  LINKEDIN_URL:
    "https://www.linkedin.com/in/ireni-ruthirakuhan/?originalSubdomain=ca",
  GITHUB_URL: "https://github.com/IreniR",

  ABOUT_CONNECT_WITH_ME: "Feel free to connect with me through the following:",
  ABOUT_DEGREE: "Software Engineer (B.Eng) | IoT Specialization",
  ABOUT_WELCOME:
    " Welcome to my Portfolio! I currently work as an Applications Developer based in Toronto, Ontario",

  JOB_DATE_1: "July 2022 - Current",
  JOB_1_TITLE: "Mobile Application Software Developer | Embedded SWE L2",
  JOB_1_LOCATION: "Vaughn, Ontario, CAN ON Concord",

  JOB_DETAIL_1:
    "Primarily working on Router application with incorporation of Zwave devices (Flutter, Dart)",
  JOB_DETAIL_2:
    "Implementation of notifications and its capabilities (Firebase, FCM, Localizable strings - Android and iOS)",
  JOB_DETAIL_3:
    "Implemented several features independently (Host and Network related)",
  JOB_DETAIL_4:
    "Extensive knowledge on Postman (Testing/Utilizing APIs/JSON Payloads)",
  JOB_DETAIL_5: "Worked in Agile/SCRUM environment",

  JOB_DATE_2: "August 2020 - August 2021",
  JOB_TITLE_2: "PC Apps and Automation Developer (Internship)",
  JOB_2_DETAIL_1: "Developed security related WatchOS App (Swift)",
  JOB_2_DETAIL_2:
    "Performed automated testing on Mobile Apps (Flutter, Dart, Swift)",
  JOB_2_DETAIL_3: "Contributed to Integration testing (Flutter and Dart)",
  JOB_2_DETAIL_4:
    "Deep knowledge in test cases and manual testing (Postman and qtest)",
  JOB_2_DETAIL_5: "Worked in Agile/SCRUM environment",
};

export default APPLICATION_CONSTANTS;
