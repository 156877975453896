import background from "../img/cloud.jpg";
import "./AboutMe.css";
import { IconContext } from "react-icons";

import { FaLinkedin, FaGit, FaEnvelope } from "react-icons/fa";
import APPLICATION_CONSTANTS from "./strings/common_strings";

export default function AboutMe() {
  return (
    <section id="about" style={{ alignSeld: "center" }}>
      <div>
        <div
          className="about-section-1"
          style={{
            width: "100%",
            height: "800px",
          }}
        ></div>
        <div
          style={{
            backgroundImage: background,
            position: "absolute",
            textAlign: "center",
            top: "25%",
            left: "2%",
          }}
        >
          <p
            className="intro-text"
            style={{
              fontSize: "40px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {APPLICATION_CONSTANTS.ABOUT_TITLE}
          </p>
          <p style={{ fontSize: "10px", fontWeight: "bold" }}>
            {APPLICATION_CONSTANTS.ABOUT_DEGREE}
          </p>
          <p
            className="mb-8 leading-relaxed"
            style={{ padding: "20px", fontSize: "15px", color: "white" }}
          >
            {APPLICATION_CONSTANTS.ABOUT_WELCOME}
          </p>
          <p style={{ color: "white", fontSize: "15px" }}>
            {APPLICATION_CONSTANTS.ABOUT_CONNECT_WITH_ME}{" "}
          </p>
          <br></br>
          <br></br>
          <div>
            <IconContext.Provider value={{ color: "black", size: 60 }}>
              <a href={APPLICATION_CONSTANTS.LINKEDIN_URL}>
                <FaLinkedin style={{ margin: 20 }} />
              </a>
              <a href={APPLICATION_CONSTANTS.GITHUB_URL}>
                <FaGit style={{ margin: 20 }} />
              </a>
              <a href="#contact">
                <FaEnvelope style={{ margin: 20 }} />
              </a>
            </IconContext.Provider>
          </div>
        </div>
      </div>
    </section>
  );
}
