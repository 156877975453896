import React from "react";
import "./Skills.css";

import api from "../img/icons/api.svg";
import aws from "../img/icons/aws.svg";
import c from "../img/icons/c.svg";
import java from "../img/icons/java.svg";
import python from "../img/icons/python.svg";
import flutter from "../img/icons/flutter.svg";
import git from "../img/icons/git.svg";
import google from "../img/icons/google.svg";
import kafka from "../img/icons/kafka.svg";
import kotlin from "../img/icons/kotlin.svg";
import firebase from "../img/icons/firebase.svg";
import docker from "../img/icons/docker.svg";
import kube from "../img/icons/kube.svg";
import swift from "../img/icons/swift.svg";
import react from "../img/icons/react.svg";

import APPLICATION_CONSTANTS from "./strings/common_strings";

export default function Skills() {
  return (
    <section id="skills">
      <div className="skills-section" style={{ backgroundColor: "black" }}>
        <p className="title">{APPLICATION_CONSTANTS.TSKILLS_TITLE}</p>
        <p className="title" style={{ fontSize: "15px" }}>
          {APPLICATION_CONSTANTS.LANGUAGES_PROTOCOLS}
        </p>

        <div className="tech-skills">
          <img
            src={react}
            alt="HTML/CCS/JS"
            style={{ width: 100, height: 100 }}
          />
          <img
            src={flutter}
            alt="Flutter/Dart"
            style={{ width: 100, height: 100 }}
          />
          <img src={kotlin} alt="Kotlin" style={{ width: 100, height: 100 }} />
          <img src={java} alt="Java" style={{ width: 100, height: 100 }} />
          <img src={c} alt="C" style={{ width: 100, height: 100 }} />
          <img src={python} alt="Python" style={{ width: 100, height: 100 }} />
          <img
            src={api}
            alt="REST/JSON/CoAP"
            style={{ width: 100, height: 100 }}
          />
          <img src={git} alt="Git" style={{ width: 100, height: 100 }} />
          <img src={swift} alt="swift" style={{ width: 100, height: 100 }} />
        </div>
        <p className="title" style={{ fontSize: "15px" }}>
          {APPLICATION_CONSTANTS.SERVICES}
        </p>
        <div className="proto-list">
          <img
            src={aws}
            alt="b1"
            style={{ width: 100, height: 100, borderRadius: 400 / 2 }}
          />
          <img
            src={kafka}
            alt="b1"
            style={{ width: 100, height: 100, borderRadius: 400 / 2 }}
          />
          <img
            src={google}
            alt="b1"
            style={{ width: 100, height: 100, borderRadius: 400 / 2 }}
          />
          <img
            src={firebase}
            alt="b1"
            style={{ width: 100, height: 100, borderRadius: 400 / 2 }}
          />
          <img
            src={docker}
            alt="b1"
            style={{ width: 100, height: 100, borderRadius: 400 / 2 }}
          />
          <img
            src={kube}
            alt="b1"
            style={{ width: 100, height: 100, borderRadius: 400 / 2 }}
          />
        </div>
      </div>
    </section>
  );
}
