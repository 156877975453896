import "./Header.css";
import React, { useState } from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBNavbar,
  MDBNavbarToggler,
} from "mdb-react-ui-kit";
import { FaBars } from "react-icons/fa";
import APPLICATION_CONSTANTS from "./strings/common_strings";

export default function App() {
  const [showNavExternal, setShowNavExternal] = useState(false);

  return (
    <>
      <div style={{ position: "fixed", top: "0", zIndex: "100" }}>
        <MDBNavbar>
          <MDBContainer fluid>
            <MDBNavbarToggler
              type="button"
              data-target="#navbarToggleExternalContent"
              aria-controls="navbarToggleExternalContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setShowNavExternal(!showNavExternal)}
            >
              <FaBars style={{ color: "black" }}></FaBars>
            </MDBNavbarToggler>
          </MDBContainer>
        </MDBNavbar>

        <MDBCollapse show={showNavExternal} style={{ color: "black" }}>
          <div className="shadow-3 p-4">
            <ul>
              <li>
                <a id="link" href="#about" style={{ textDecoration: "none" }}>
                  {APPLICATION_CONSTANTS.ABOUT_NAV}
                </a>
              </li>
              <li>
                <a
                  id="link"
                  href="#experience"
                  style={{ textDecoration: "none" }}
                >
                  {APPLICATION_CONSTANTS.EXPERIENCE_NAV}
                </a>
              </li>
              <li>
                <a
                  id="link"
                  href="#projects"
                  style={{ textDecoration: "none" }}
                >
                  {APPLICATION_CONSTANTS.PROJECTS_TITLE}
                </a>
              </li>{" "}
              <li>
                {" "}
                <a id="link" href="#skills" style={{ textDecoration: "none" }}>
                  {APPLICATION_CONSTANTS.SKILLS_TITLE}
                </a>
              </li>
              <li>
                <a id="link" href="#cert" style={{ textDecoration: "none" }}>
                  {APPLICATION_CONSTANTS.CERTIFICATES_TITLE}
                </a>
              </li>
              <li>
                <a id="link" href="#contact" style={{ textDecoration: "none" }}>
                  {APPLICATION_CONSTANTS.CONTACT_TITLE}
                </a>
              </li>
            </ul>
          </div>
        </MDBCollapse>
      </div>
    </>
  );
}
